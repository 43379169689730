

import Header from '@/components/Header.vue'

export default {
  name: 'Home',
  components: {
    Header,
  }
}
